import React, { useState, useEffect, useContext} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { withRouter, useHistory } from 'react-router-dom';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AppContext from '../common/AppContext';
import RouteConstants from "../common/RouteConstants";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme) => ({
    teamsMenuRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: 'transparent',
    },
    menuLabel: {
        background: 'transparent',
        color: "#b5b5b5",
        alignItems: 'center',
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid transparent',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
        '& .MuiAccordionSummary-root': {
            '& .MuiAccordionSummary-expandIcon': {
                color: '#fff',
                padding: theme.spacing(.5, 1.5),
            },
        },
    },
    highlightedMenu: {
        color: '#b5b5b5',
        alignItems: 'center',
        background: "transparent",
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid #c8102e',
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
        '& .MuiAccordionSummary-root': {
            '& .MuiAccordionSummary-expandIcon': {
                color: '#fff',
                padding: theme.spacing(.5, 1.5),
            },
        },
    },

}));

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 'unset',
        color: '#fff',
        padding: '0 8px',
        '&$expanded': {
            minHeight: 'unset',
            padding: '0 8px',
        },
    },
    content: {
        color: '#b5b5b5',
        margin: '8px 0',
        '&$expanded': {
            color: '#b5b5b5',
            margin: '8px 0',
        },
    },
    expanded: {
        color: '#fff',
    },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiAccordionDetails);

const TeamsAccordionMenu = (props) => {
    const classes = useStyles();
    const { allTeams } = useContext(AppContext);
    const [expanded, setExpanded] = useState('');
    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        setSelectedTab(props.selectedTab);
    }, [props.selectedTab]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion
                square
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                className={selectedTab === "Team" ? classes.highlightedMenu : classes.menuLabel}
            >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='caption'>TEAMS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.teamsMenuRoot}>
                        <List component="nav" aria-label="main mailbox folders">
                            {allTeams && allTeams.length > 0 && allTeams.map(team => {
                                return <ListItem
                                    button
                                    style={{ 'cursor': 'pointer' }}
                                    onClick={() => {
                                        sessionStorage.removeItem("activeTileTeam");
                                        props.hideDrawer();
                                        let teamIndex = allTeams.findIndex(t => t.teamNameSEO === team.teamNameSEO);
                                        sessionStorage.setItem("activeTileTeam", teamIndex !== -1 ? teamIndex : 0);
                                        props.history.push({
                                            pathname: RouteConstants.LOCKER_BY_TEAM + "/" + team.teamNameSEO,
                                            state: {
                                                team: "team",
                                                edition: "",
                                                fetchTeamData: true,
                                                teamName: team.teamNameSEO,
                                                teamId: team.teamId,
                                                teamIndex: teamIndex
                                            }
                                        });
                                    }}
                                >
                                    <ListItemIcon>
                                        {team.logoURL ? <img src={team.logoURL} height={25} width={25} alt={team.teamName}></img> :
                                            <div style={{ height: '30px', width: '30px' }}>&nbsp;</div>}
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography variant='caption'>{team.teamName}</Typography>} />
                                </ListItem>
                            })}
                        </List>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default withRouter(TeamsAccordionMenu);
