import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AppContext from '../common/AppContext';
import RouteConstants from "../common/RouteConstants";

const useStyles = makeStyles(theme => ({
    menuLabel: {
        color: "#b5b5b5",
        alignItems: 'center',
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid transparent',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
    },
    highlightedMenu: {
        color: '#b5b5b5',
        alignItems: 'center',
        background: "transparent",
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2.5),
        borderBottom: '3px solid #c8102e',
        borderRadius: '0',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
        },
    },
    root: {
        flexGrow: 1,
    },
    megaMenu: {
        maxWidth: '720px'
    },
    megaMenuBg: {
        '& .MuiPopover-paper': {
            border: '1px solid rgba(255, 255, 255, .3)',
            background: '#575757 !important',
            '& .MuiListItem-button': {
                '&:hover': {
                    background: '#413e3e',
                },
                '& .MuiListItemText-root': {
                    color: '#fff',
                },
            },
        },
    },
}));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: '#413e3e',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const TeamsMenu = (props) => {
    const classes = useStyles();
    const { allTeams } = useContext(AppContext);
    const [anchorEl, setAnchorEl] = useState(null);    
    const [selectedTab, setSelectedTab] = useState();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setSelectedTab(props.selectedTab);
    }, [props.selectedTab]);

    let teamListUI = allTeams && allTeams.length > 0 ? allTeams.map(team => (
        <Grid item xs={12} sm={6} md={4}>
            <StyledMenuItem
                onClick={() => {
                    let activeTileTeam = parseInt(sessionStorage.getItem("activeTileTeam"));
                    let selectedTab = sessionStorage.getItem("selectedTab");
                    let teamIndex = allTeams.findIndex(t => t.teamNameSEO === team.teamNameSEO);
                    if (activeTileTeam === teamIndex && selectedTab.toLowerCase() === "team") {
                        setAnchorEl(null);
                        return;
                    }
                    else {
                        sessionStorage.removeItem("activeTileTeam");
                        sessionStorage.setItem("activeTileTeam", teamIndex !== -1 ? teamIndex : 0);
                        setAnchorEl(null);
                        props.history.push({
                            pathname: RouteConstants.LOCKER_BY_TEAM + "/" + team.teamNameSEO,
                            state: {
                                team: "team",
                                edition: "",
                                fetchTeamData: true,
                                teamName: team.teamNameSEO,
                                teamId: team.teamId,
                                teamIndex: teamIndex
                            }
                        });
                    }
                    props.setHighlightedMenu("team");
                }}>
                <ListItemIcon>
                    {team.logoURL ? <img src={team.logoURL} height={33} width={33} alt={team.teamName}></img> :
                        <div style={{ height: '33px', width: '33px' }}>&nbsp;</div>}
                </ListItemIcon>
                <ListItemText primary={<Typography variant='caption'>{team.teamName}</Typography>} />
            </StyledMenuItem>
        </Grid>
    )) : null;

    return (<div>
            <Button
                style={{ cursor: 'pointer', underline: 'none' }}
                className={selectedTab === "Team" ? classes.highlightedMenu : classes.menuLabel}
                aria-controls="customized-menu"
                aria-haspopup="true"
                color={props.buttonColor}
                onClick={handleClick}                
            >
                Teams
            </Button>           
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}                
                className={classes.megaMenuBg}
            >
                <Grid container className={classes.megaMenu}>
                    {teamListUI}
                </Grid>
            </StyledMenu>
        </div>
    );
}

export default withRouter(TeamsMenu);
